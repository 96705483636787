<template>
    <div>
        <div class="field">
            <label class="switch-label">
                <input class="switch" type="checkbox" v-model="isEnabled" /> Enable Integration
            </label>
        </div>
        <div v-if="isEnabled">
            <div :class="integrationDropdownActive ? 'dropdown is-active' : 'dropdown'">
                <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="integrationDropdownActive = !integrationDropdownActive" @blur="delayIntegrationDropdown">
                        <span>{{ integrationTypeOptions.find(x => x.type == integrationType).label }}</span>
                        <span class="icon is-small">
                            <font-awesome-icon :icon="['fa', 'angle-down']" />
                        </span>
                    </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a role="button"
                           v-for="(option, index) in integrationTypeOptions"
                           :key="index"
                           :value="option.type"
                           :class="integrationType == option.type ? 'dropdown-item is-active' : 'dropdown-item'"
                           @click="setIntegrationType(option.type)">
                            {{option.label}}
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="integrationTypeValid">
              <div class="field mt-4">
                <label class="label">Nice Customer Id</label>
                <div class="control">
                    <input class="input" type="text" v-model="niceIntegration.niceCustomerId">
                </div>
              </div>
              <div class="field">
                  <label class="label">Access Key Id</label>
                  <div class="control">
                      <input class="input" type="text" v-model="niceIntegration.accessKeyId">
                  </div>
              </div>
              <div class="field">
                  <label class="label">Access Key Secret</label>
                  <div class="control">
                      <input class="input" type="text" v-model="niceIntegration.accessKeySecret">
                  </div>
              </div>
              <div class="field">
                  <label class="label">Region</label>
                  <div class="control">
                      <input class="input" type="text" v-model="niceIntegration.region">
                  </div>
              </div>
              <div class="field" v-if="integrationType == 3">
                  <label class="label">Chat API Version</label>
                  <div class="control">
                      <input class="input" type="text" v-model="niceIntegration.chatApiVersion">
                  </div>
              </div>
              <div class="field" v-if="integrationType == 7">
                  <label class="label">Brand Id</label>
                  <div class="control">
                      <input class="input" type="text" v-model="niceIntegration.brandId">
                  </div>
              </div>
              <div class="field" v-if="integrationType == 7">
                  <label class="label">Integration Id</label>
                  <div class="control">
                      <input class="input" type="text" v-model="niceIntegration.channelIntegrationId">
                  </div>
              </div>
              <div class="columns is-multiline">
                  <div class="field column is-narrow">
                      <label class="switch-label">
                          <input class="switch" type="checkbox" v-model="niceIntegration.isUserHub" /> isUserHub
                      </label>
                  </div>
                  <div class="field column is-narrow">
                      <label class="switch-label">
                          <input class="switch" type="checkbox" v-model="niceIntegration.isFedRamp" /> isFedRamp
                      </label>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
    import { secureGet, securePost, securePut } from '../secureFetch.js';

    export default {
        name: 'Nice',
        props: {
            publicAccountId: {
                type: String,
                default: null
            },
            integrationLoaded: {
                type: Boolean,
                default: false
            },
            loadedIntegrationType: {
                type: Number,
                default: 0
            }
        },
        computed: {
            hostUrl: function () {
              return this.$store.state.hostUrl;
            },
            integrationTypeValid: function () {
              return this.integrationType != null && this.integrationType > 0
            }
        },
        data() {
            return {
                niceIntegration: {},
                niceExternalAccountExists: false,
                niceExternalAccountExists: false,
                isEnabled: false,
                errorMessage: null,
                integrationDropdownActive: false,
                initialIntegrationType: 0,
                integrationType: 0,
                integrationTypeOptions: [
                  { label: "Select", type: 0 },
                  { label: "Nice Max Agent", type: 3 },
                  { label: "Nice DFO BYOC", type: 7},
                ],
                initialNiceDetails: {},
                initialNiceDfoDetails: {},
            }
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
            }
        },
        mounted() {
            if (this.loadedIntegrationType) {
              this.initialIntegrationType = this.loadedIntegrationType;
              this.integrationType = this.loadedIntegrationType;
            }
            this.getNiceIntegration();
            this.getNiceDfoIntegration();
            this.initializeNiceIntegration();
        },
        methods: {
            delayIntegrationDropdown() {
                setTimeout(() => this.integrationDropdownActive = false, 500);
            },
            setIntegrationType(type) {
                this.integrationType = type;
                if (type == 7) {
                  this.niceIntegration = this.initialNiceDfoDetails;
                }
                else if (type == 3) {
                  this.niceIntegration = this.initialNiceDetails;
                }
            },
            getNiceIntegration() {
                this.isLoading = true;
                secureGet(this.hostUrl + 'v1/nice/' + this.publicAccountId)
                    .then(data => {
                        if (data && data.success) {
                            if (this.integrationType == 3) {
                              this.niceIntegration = data.niceIntegration;
                            }
                            this.initialNiceDetails = data.niceIntegration;
                            this.niceExternalAccountExists = true;
                        }

                        this.isLoading = false;
                    })
            },
            getNiceDfoIntegration() {
                this.isLoading = true;
                secureGet(this.hostUrl + 'v1/nicedfo/' + this.publicAccountId)
                    .then(data => {
                        if (data && data.success) {
                            if (this.integrationType == 7) {
                              this.niceIntegration = data.niceIntegration;
                            }
                            this.initialNiceDfoDetails = data.niceIntegration;
                            this.niceDfoExternalAccountExists = true;
                        }

                        this.isLoading = false;
                    })
            },
            initializeNiceIntegration() {
                if (this.integrationLoaded) {
                    this.isEnabled = true;
                }
            },
            save() {
                this.errorMessage = null;

                if (this.validateIntegration()) {
                  if (this.integrationLoaded && !this.isEnabled) {
                      this.$emit("delete-account-integration", this.initialIntegrationType);
                      this.initialIntegrationType = 0;
                  }
                  else if (this.initialIntegrationType > 0 &&
                           this.initialIntegrationType != this.integrationType) {
                      this.$emit("delete-account-integration", this.initialIntegrationType);
                  }
                  if (this.isEnabled) {
                      if (!this.integrationLoaded || this.initialIntegrationType != this.integrationType) {
                          this.$emit("create-account-integration", this.integrationType);
                      }

                      if (this.integrationType == 7) {
                        if (this.niceDfoExternalAccountExists) {                   
                            this.updateNiceIntegration();
                        }
                        else {
                            this.createNiceIntegration();
                        }
                      }
                      else if (this.integrationType == 3) {
                        if (this.niceExternalAccountExists) {                   
                            this.updateNiceIntegration();
                        }
                        else {
                            this.createNiceIntegration();
                        }
                      }
                  }
                }
            },
            createNiceIntegration() {
                this.isLoading = true;
                const url = this.integrationType == 7
                  ? `${this.hostUrl}v1/nicedfo/`
                  : `${this.hostUrl}v1/nice/`

                securePost(url + this.publicAccountId, this.niceIntegration)
                    .then((data) => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.niceIntegration = data.niceIntegration;
                            this.initialIntegrationType = this.integrationType;
                            if (type == 7) {
                              this.initialNiceDfoDetails = data.niceIntegration;
                            }
                            else if (type == 3) {
                              this.initialNiceDetails = data.niceIntegration;
                            }
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            updateNiceIntegration() {
                this.isLoading = true;
                const url = this.integrationType == 7
                  ? `${this.hostUrl}v1/nicedfo/`
                  : `${this.hostUrl}v1/nice/`

                securePut(url + this.publicAccountId, this.niceIntegration)
                    .then(data => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.niceIntegration = data.niceIntegration;
                            this.initialIntegrationType = this.integrationType;
                            
                            if (type == 7) {
                              this.initialNiceDfoDetails = data.niceIntegration;
                            }
                            else if (type == 3) {
                              this.initialNiceDetails = data.niceIntegration;
                            }
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            validateIntegration() {
                var errorMessages = [];

                if (this.integrationType < 1) {
                  errorMessages.push('Must Select Integration Type');
                }

                if (!this.niceIntegration.niceCustomerId || this.niceIntegration.niceCustomerId < 1) {
                    errorMessages.push('Nice Customer Id is required');
                }

                if (!this.niceIntegration.accessKeyId || this.niceIntegration.accessKeyId?.trim() === '') {
                    errorMessages.push('Access Key Id is required');
                }

                if (!this.niceIntegration.accessKeySecret || this.niceIntegration.accessKeySecret?.trim() === '') {
                    errorMessages.push('Access Key Secret is required');
                }

                if (!this.niceIntegration.region || this.niceIntegration.region.trim() === '') {
                    errorMessages.push('Region is required');
                }

                if (this.integrationType == 7) {
                    if (!this.niceIntegration.brandId || this.niceIntegration.brandId < 1) {
                      errorMessages.push('Brand Id is required');
                    }

                    if (!this.niceIntegration.channelIntegrationId || this.niceIntegration.channelIntegrationId.trim() === '') {
                        errorMessages.push('Integration Id is required');
                    }
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                    document.getElementById("column-right").scrollTo({top: 0, behavior: "smooth"});
                }

                return errorMessages.length === 0;
            }
        }
    };
</script>
<template>
    <div>
        <div class="columns is-multiline">
            <div class="field column is-half">
                <label class="label">Id</label>
                <p class="has-text-grey-lighter mt-3"> {{ account.id ? account.id + ' (' + account.publicId + ')' : '-' }}</p>
            </div>
            <div class="field column is-half">
                <label class="label">Salesforce Id</label>
                <div class="control">
                    <b-input placeholder="Salesforce Id..." v-model="account.internalSalesforceId"></b-input>
                </div>
            </div>
        </div>
        <div class="columns is-multiline">
            <div class="field column is-half">
                <label class="label">Name</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Account name..." v-model="account.name">
                </div>
            </div>
            <div class="field column is-half">
                <label class="label">Company Name</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Company name..." v-model="account.companyName">
                </div>
            </div>
        </div>
        <div class="columns is-multiline">
            <div class="field column is-narrow">
                <label class="label">Contact Email</label>
                <div class="control">
                    <input class="input" type="email" placeholder="Contact email..." v-model="account.email">
                </div>
            </div>
            <div class="field column is-narrow">
                <div class="field">
                    <label class="label">Timezone</label>
                    <div class="field has-addons">
                        <div :class="timezoneDropdownActive ? 'dropdown is-right is-active' : 'dropdown is-right'">
                            <div class="dropdown-trigger is-right">
                                <div class="control has-icons-left">
                                    <input type="text" placeholder="Search timezones..." aria-controls="dropdown-menu" @click="timezoneDropdownActive = !timezoneDropdownActive" class="input is-transparent" v-model="timezoneSearch" @blur="delayCloseZoneDropdown()" @keyup="timezoneDropdownActive = true">
                                    <span class="icon is-left">
                                        <font-awesome-icon :icon="['fa', 'search']" />
                                    </span>
                                </div>
                            </div>
                            <div v-if="timezoneDropdownActive" class="dropdown-menu timezone-dropdown" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="timezone in timezonesFiltered" :key="timezone.value"
                                       class="dropdown-item" @click="setTimezone(timezone)">
                                        {{ timezone.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field column is-narrow">
                <label class="label">Account Status</label>
                <div :class="statusDropdownActive ? 'dropdown is-active' : 'dropdown'">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="statusDropdownActive = !statusDropdownActive" @blur="delayCloseStatusDropdown">
                            <span>{{ statusDictionary[account.status] }}</span>
                            <span class="icon is-small">
                                <font-awesome-icon :icon="['fa', 'angle-down']" />
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a role="button" :class="account.status == 1 ? 'dropdown-item is-active' : 'dropdown-item'" @click="setAccountStatus(1)">
                                Active
                            </a>
                            <a role="button" :class="account.status == 2 ? 'dropdown-item is-active' : 'dropdown-item'" @click="setAccountStatus(2)">
                                Inactive
                            </a>
                            <a role="button" :class="account.status == 3 ? 'dropdown-item is-active' : 'dropdown-item'" @click="setAccountStatus(3)">
                                Deleted
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field column is-narrow">
                <label class="label">Created</label>
                <p class="has-text-grey-lighter mt-3">{{ !account.created ? 'N/A' : new Date(account.created).toLocaleString() }}</p>
            </div>

            <div class="field column is-narrow">
                <label class="label">Updated</label>
                <p class="has-text-grey-lighter mt-3">{{ !account.updated ? 'N/A' : new Date(account.updated).toLocaleString() }}</p>
            </div>
        </div>

        <div class="columns">
            <div class="field column is-half pt-0">
                <label class="label">Capacity Org Id</label>
                <div class="control">
                    <input class="input" placeholder="Capacity Org Id..." v-model="account.capacityOrganizationId">
                </div>
            </div>
            <div class="field column is-half pt-0">
                <label class="label">TCR Brand Id</label>
                <div class="control">
                    <input class="input" placeholder="TCR Brand Id..." v-model="account.tcrBrandId">
                </div>
            </div>
        </div>

        <div class="columns">
          <div class="field column is-one-third">
                <label class="label">Account Style Version</label>
                <div :class="styleVersionDropdownActive ? 'dropdown is-active' : 'dropdown'">
                    <div class="dropdown-trigger">
                        <button class="button template-button" aria-haspopup="true" aria-controls="dropdown-menu" @click="styleVersionDropdownActive = !styleVersionDropdownActive" @blur="delayClostStyleVersionDropdown">
                            <span class="is-flex-grow-1">{{ styleVersionDictionary[account.styleVersion] }}</span>
                            <span class="icon is-small">
                                <font-awesome-icon :icon="['fa', 'angle-down']" />
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a v-for="(value, key) in styleVersionDictionary" :key="key" role="button" :class="account.styleVersion == key ? 'dropdown-item is-active' : 'dropdown-item'" @click="setAccountStyleVersion(parseInt(key))">
                                {{ value }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Billing :accountId="account.id"></Billing>

        <div class="has-top-border">
            <h1 class="title">
                Support
            </h1>

            <p class="field">
                Use the button below to log in to the account as a Support user. The support login session will expire after 30 minutes. When the session is expired, you will need to come here to log in as the support user again.
            </p>

            <div class="field">
                <button :class="isLoadingSupportUrl ? 'button is-primary is-loading' : 'button is-primary'" @click="loginAsSupport">
                    Login as Support
                </button>
            </div>
        </div>

        <ProductPermissions :productAccess="account.productAccess"
                            @accessUpdated="setProductAccess"
                            message="Permissionless accounts are not currently supported within Foundation. To avoid errors, please make sure at least one permission is selected below." />

        <AccountLines :accountPublicId="publicAccountId" />

        <AccountUsers :accountPublicId="publicAccountId" />
    </div>
</template>

<script>
    import timezones from '@/timezones.json';

    import { securePut, securePost } from '../secureFetch.js';

    import Billing from '../components/Billing';
    import ProductPermissions from '../components/ProductPermissions';
    import AccountLines from '../components/AccountLines';
    import AccountUsers from '../components/AccountUsers';
    import access from '../models/access';

    export default {
        name: 'AccountDetails',
        components: {
            Billing,
            ProductPermissions,
            AccountLines,
            AccountUsers
        },
        props: {
            publicAccountId: {
                type: String,
                default: null
            },
            account: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
            platformUrl: function () {
                return this.$store.state.platformUrl;
            },
            timezonesFiltered: function () {
                const search = this.timezoneSearch ? this.timezoneSearch : "";

                return this.timezones.filter(function (timezone) {
                    return timezone.name.toUpperCase().match(search.toUpperCase())
                        || timezone.value.toUpperCase().match(search.toUpperCase())
                });
            },
            hasPaymentsProduct: function () {
                const paymentsProductId = access.productIds.Payments;
                const hasAccess = this.account?.productAccess?.some((x) => x.productId == paymentsProductId);
                return hasAccess;
            }
        },
        data() {
            return {
                timezoneDropdownActive: false,
                statusDictionary: { 1: 'Active', 2: 'Inactive', 3: 'Deleted' },
                styleVersionDictionary: { 1: 'Default', 2: 'RingCentral' },
                statusDropdownActive: false,
                styleVersionDropdownActive: false,
                errorMessage: null,
                isLoadingSupportUrl: false,
                defaultErrorMessage: "Something didn't work.",
                timezones: [],
                timezoneSearch: ""
            }
        },
        watch: {
            account(value) {
                this.getTimeszones();
                const timezone = this.timezones.find((x) => x.value == value.timezone);
                if (timezone) this.timezoneSearch = timezone.name;
            },
            errorMessage(value) {
                this.$emit("error", value);
            }
        },
        methods: {
            delayCloseZoneDropdown() {
                setTimeout(() => this.zoneDropdownActive = false, 500);
            },
            delayCloseStatusDropdown() {
                setTimeout(() => this.statusDropdownActive = false, 500);
            },
            delayClostStyleVersionDropdown() {
                setTimeout(() => this.styleVersionDropdownActive = false, 500);
            },
            setAccountStatus(status) {
                this.account.status = status;
                this.statusDropdownActive = false;
            },
            setAccountStyleVersion(styleVersion) {
                this.account.styleVersion = styleVersion;
                this.styleVersionDropdownActive = false;
            },
            setProductAccess(productAccess) {
                this.account.productAccess = productAccess;
            },
            getTimeszones() {
                // Read in the timezones from timezones.json, move the UTC offset to the end, then sort alphabetically.
                this.timezones = Object.entries(timezones).map((x) => ({ name: x[1], value: x[0] }));
                this.timezones.forEach((x) => {
                    const offset = x.name.substring(0, x.name.indexOf(' ') + 1); // Include the space
                    let name = x.name.replace(offset, '');
                    x.name = name.concat(' ', offset.substring(0, offset.length - 1)); // Remove the space
                });
                this.timezones.sort((x, y) => x.name < y.name ? -1 : 1);
            },
            setTimezone(timezone) {
                this.account.timezone = timezone.value;
                this.timezoneSearch = timezone.name;
                this.timezoneDropdownActive = false;
            },
            setAccount(account) {
                this.account.companyName = account.companyName;
                this.account.created = account.created;
                this.account.defaultAdminUserId = account.defaultAdminUserId;
                this.account.email = account.email;
                this.account.id = account.id;
                this.account.integrations = account.integrations;
                this.account.internalSalesforceId = account.internalSalesforceId;
                this.account.name = account.name;
                this.account.productAccess = account.productAccess;
                this.account.publicId = account.publicId;
                this.account.status = account.status;
                this.account.timezone = account.timezone;
                this.account.updated = account.updated;
                this.account.userFirstName = account.userFirstName;
                this.account.userLastName = account.userLastName;
                this.account.styleVersion = account.styleVersion;
            },
            minutesToHourString(minutes) {
                return (minutes < 0 ? '' : '+') + (`0${minutes / 60 ^ 0}`.slice(-2) + ':' + ('0' + minutes % 60).slice(-2));
            },
            save() {
                if (this.validateAccount()) {
                    if (this.publicAccountId) {
                        this.updateAccount();
                    }
                    else {
                        this.createAccount();
                    }
                }
            },
            createAccount() {
                this.isLoading = true;
                securePost(this.hostUrl + "v1/account/create-account", this.account)
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.setAccount(data.account);
                            this.publicAccountId = data.account.id;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            updateAccount() {
                this.isLoading = true;

                securePut(this.hostUrl + "v1/account/" + this.publicAccountId, this.account)
                    .then((data) => {
                        this.isLoading = false;

                        if (data.success) {
                            this.setAccount(data.account);
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            validateAccount() {
                var errorMessages = [];

                if (!this.account.name || this.account.name.trim() === '') {
                    errorMessages.push('Name is required');
                }

                if (!this.account.companyName || this.account.companyName.trim() === '') {
                    errorMessages.push('Company Name is required');
                }

                if (!this.account.email || this.account.email.trim() === '') {
                    errorMessages.push('Contact Email is required');
                }

                if (!this.account.productAccess || this.account.productAccess.length < 1) {
                    errorMessages.push('At least one product permission is required');
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                }

                return errorMessages.length === 0;
            },
            loginAsSupport() {
                this.isLoadingSupportUrl = true;

                var supportLoginRequest = {
                    accountId: this.account.id 
                };

                securePost(this.hostUrl + "v1/user/support-login", supportLoginRequest)
                    .then((data) => {
                        this.isLoadingSupportUrl = false;
                        if (data.success) {
                            var url = this.platformUrl + "login?token=" + data.token;
                            window.open(url, '_blank');
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoadingSupportUrl = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            }
        }
    };
</script>

<style scoped>
    .has-bottom-border {
        border-bottom: 1px solid #808080;
        padding-bottom: 1em;
    }

    .feature-list:first-child {
        border-top: 1px solid #808080;
        padding-top: 1em;
    }

    .has-background-alt {
        background-color: #161716;
        margin-top: -1.425em;
    }

    .timezone-dropdown {
        max-height: 24em;
        overflow: auto;
    }

    .template-button {
        min-width: 25em;
        justify-content: flex-start;
        text-align: left;
    }
</style>
<template>
    <div>
        <div class="control has-top-border">
            <h1 class="title">Billing</h1>
            <article v-if="errorMessage" class="message is-danger">
                <div class="message-body">
                    {{ errorMessage }}
                </div>
            </article>
            <article v-if="successMessage" class="message is-success">
                <div class="message-body">
                    {{ successMessage }}
                </div>
            </article>
        </div>

        <div class="mt-5" v-if="accountId && !isLoading" id="billingColumns">
            <div class="columns is-multiline mt-5" v-for="(plan, index) in accountBillingPlans" :key="index">
                <div class="field column is-narrow">
                    <label v-if="plan.isMessagePlan" class="label">Message Plan Count</label>
                    <label v-if="plan.isUserPlan" class="label">User Plan Count</label>
                    <div class="control">
                        <input class="input" type="text" :disabled="plan.isActive || plan.billingTerm === 3" placeholder="Plan Count..." v-model="plan.planCount">
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Billing Term</label>
                    <div :class="plan.dropdownActive ? 'dropdown is-active' : 'dropdown'">
                        <div class="dropdown-trigger">
                            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" :disabled="plan.isActive" @click="plan.dropdownActive = !plan.dropdownActive" @blur="delayCloseTermDropdown">
                                <span class="is-flex-grow-1">{{ plan.billingTerm ? planBillingDisplay(plan) : 'Unknown' }}</span>
                                <span class="icon is-small">
                                    <font-awesome-icon :icon="['fa', 'angle-down']" />
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu" role="menu">
                            <div class="dropdown-content" v-for="(item, index) in billingTerms" :key="index">
                                <a :class="plan.billingTerm === item.id ? 'dropdown-item is-active' : 'dropdown-item'" v-if="item.id !== 3 || plan.isMessagePlan" @click="setBillingTerm(item.id, plan)">
                                    {{item.name}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Term Start Date</label>
                    <div class="control">
                        <input class="input" type="text" :disabled="plan.isActive" placeholder="MM/DD/YYYY" v-model="plan.startDate">
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Billing Day</label>
                    <div class="control">
                        <input class="input" type="text" :disabled="plan.isActive" placeholder="Billing Day..." v-model="plan.billingDay">
                    </div>
                </div>

                <div class="field column is-narrow">
                    <label class="label">Term End Date</label>
                    <div class="control">
                        <input class="input" type="text" :disabled="!plan.isActive" placeholder="MM/DD/YYYY" v-model="plan.endDate">
                    </div>
                </div>

                <div class="button-contain column">
                    <button class="button is-primary" :disabled="plan.isActive" @click.stop="saveBillingPlan(plan)">
                        Save Plan
                    </button>
                    <button class="button is-light ml-3" :disabled="!plan.isActive" @click.stop="stopBillingPlan(plan)">
                        Cancel Plan
                    </button>
                </div>
            </div>
        </div>

        <Loading v-if="isLoading"></Loading>
    </div>
</template>

<script>
import { secureGet, securePost } from '../secureFetch.js';

    import Loading from "./Loading.vue";

    export default {
        name: 'Billing',
        components: {
            Loading
        },
        props: {
            accountId: {
                type: String,
                default: null
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            }
        },
        data() {
            return {
                isLoading: true,
                errorMessage: null,
                successMessage: null,
                termDropdownActive: false,
                messageBilling: { planCount: null, billingTerm: null, billingDay: null, startDate: null, endDate: null, isActive: false, isMessagePlan: true, dropdownActive: false },
                userBilling: { planCount: null, billingTerm: null, billingDay: null, startDate: null, endDate: null, isActive: false, isUserPlan: true, dropdownActive: false },
                accountBillingPlans: [],
                billingTerms: [
                    { id: 1, name: 'Monthly' },
                    { id: 2, name: 'Annually' },
                    { id: 3, name: 'Usage' }
                ]
            }
        },
        watch: {
            accountId: function() {
                this.getAccountBilling();
            }
        },
        mounted() {
            this.getAccountBilling();
        },
        methods: {
            getAccountBilling() {
                if (this.accountId != null) {
                    this.isLoading = true;
                    this.isLoadingPermissions = true;
                    this.accountBillingPlans.push(this.messageBilling);
                    this.accountBillingPlans.push(this.userBilling);

                    secureGet(this.hostUrl + "v1/billing/all-plans/" + this.accountId)
                        .then(data => {
                            this.isLoading = false;

                            if (data && data.success) {
                                this.accountBillingPlans = [];
                                this.setMessagePlan(data.messagePlan);
                                this.setUserPlan(data.userPlan);
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            },
            setBillingTerm(id, plan) {
                plan.billingTerm = id;
                plan.dropdownActive = false;

                if (id === 3) {
                    plan.planCount = null;
                }
            },
            saveBillingPlan(plan) {
                if (plan.isMessagePlan) {
                    this.saveMessagePlan(plan);
                }
                else if (plan.isUserPlan) {
                    this.saveUserPlan(plan);
                }
            },
            saveMessagePlan(messagePlan) {
                if (this.validateStartMessageBilling(messagePlan)) {
                    const startPlanRequest = {
                        messagesInPlan: parseInt(messagePlan.planCount),
                        accountId: this.accountId,
                        billingTerm: messagePlan.billingTerm,
                        billingDay: parseInt(messagePlan.billingDay),
                        startDate: new Date(messagePlan.startDate),
                    };

                    securePost(this.hostUrl + "v1/billing/start-message-plan", startPlanRequest)
                        .then((data) => {
                            this.isLoading = false;

                            if (data.success) {
                                this.errorMessage = null;
                                this.successMessage = 'Saved Successfully.';
                                messagePlan.isActive = true;
                            }
                            else {
                                this.errorMessage = data.message;
                                if (!this.errorMessage) {
                                    this.errorMessage = this.defaultErrorMessage;
                                }
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            },
            saveUserPlan(userPlan) {
                if (this.validateStartUserBilling(userPlan)) {
                    const startPlanRequest = {
                        usersInPlan: parseInt(userPlan.planCount),
                        accountId: this.accountId,
                        billingTerm: userPlan.billingTerm,
                        billingDay: parseInt(userPlan.billingDay),
                        startDate: new Date(userPlan.startDate),
                    };

                    securePost(this.hostUrl + "v1/billing/start-user-plan", startPlanRequest)
                        .then((data) => {
                            this.isLoading = false;

                            if (data.success) {
                                this.errorMessage = null;
                                this.successMessage = 'Saved Successfully.';
                                userPlan.isActive = true;
                            }
                            else {
                                this.errorMessage = data.message;
                                if (!this.errorMessage) {
                                    this.errorMessage = this.defaultErrorMessage;
                                }
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }


            },
            stopBillingPlan(billingPlan) {
                if (this.validateStopBilling(billingPlan)) {
                    if (billingPlan.isMessagePlan) {
                        this.stopMessagePlan(billingPlan);
                    }
                    else if (billingPlan.isUserPlan) {
                        this.stopUserPlan(billingPlan);
                    }
                }
            },
            stopMessagePlan(messagePlan) {
                const stopPlanRequest = {
                    endDate: new Date(messagePlan.endDate),
                    accountId: this.accountId,
                };

                securePost(this.hostUrl + "v1/billing/stop-message-plan", stopPlanRequest)
                    .then((data) => {
                        this.isLoading = false;

                        if (data.success) {
                            this.errorMessage = null;
                            this.successMessage = 'Plan Cancelled Successfully.';
                        }
                        else {
                            this.errorMessage = data.message;
                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            stopUserPlan(userPlan) {
                const stopPlanRequest = {
                    endDate: new Date(userPlan.endDate),
                    accountId: this.accountId,
                };

                securePost(this.hostUrl + "v1/billing/stop-user-plan", stopPlanRequest)
                    .then((data) => {
                        this.isLoading = false;

                        if (data.success) {
                            this.errorMessage = null;
                            this.successMessage = 'Plan Cancelled Successfully.';
                        }
                        else {
                            this.errorMessage = data.message;
                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            setMessagePlan(messagePlan) {
                let messageBilling = this.messageBilling;

                if (messagePlan) {
                    messageBilling = messagePlan;
                    const newStartDate = new Date(messageBilling.startDate);
                    let newEndDate = null;
                    if (messagePlan.endDate) newEndDate = new Date(messageBilling.endDate);

                    messageBilling.startDate = this.convertSimpleDate(newStartDate);
                    if (newEndDate) messageBilling.endDate = this.convertSimpleDate(newEndDate);
                    messageBilling.planCount = messagePlan.messagesInPlan;
                    messageBilling.isMessagePlan = true;
                    messageBilling.dropdownActive = false;
                }

                this.accountBillingPlans.push(messageBilling);
            },
            setUserPlan(userPlan) {
                let userBilling = this.userBilling;

                if (userPlan) {
                    userBilling = userPlan;
                    const newStartDate = new Date(userBilling.startDate);
                    let newEndDate = null;
                    if (userPlan.endDate) newEndDate = new Date(userBilling.endDate);

                    userBilling.startDate = this.convertSimpleDate(newStartDate);
                    if (newEndDate) userBilling.endDate = this.convertSimpleDate(newEndDate);
                    userBilling.planCount = userPlan.usersInPlan;
                    userBilling.isUserPlan = true;
                    userBilling.dropdownActive = false;
                }

                this.accountBillingPlans.push(userBilling);
            },
            convertSimpleDate(date) {
                const mm = (date.getMonth() + 1).toString();
                const dd = date.getDate().toString();
                const yyyy = date.getFullYear().toString();
                return mm + '/' + dd + '/' + yyyy;
            },
            planBillingDisplay(billingPlan) {
                return this.billingTerms.find((x) => x.id == billingPlan.billingTerm).name;
            },
            validateStartMessageBilling(messagePlan) {
                var errorMessages = [];

                if (!messagePlan.billingTerm || messagePlan.billingTerm < 1) {
                    errorMessages.push('Billing Term is required.');
                }

                if (messagePlan.billingTerm !== 3 && (!messagePlan.planCount || isNaN(messagePlan.planCount) || parseInt(messagePlan.planCount) < 1)) {
                    errorMessages.push('Plan count is required when annual or monthly term is selected.');
                }

                if (!messagePlan.startDate || messagePlan.startDate.trim() === '') {
                    errorMessages.push('Term Start Date is required');
                }
                else {
                    if (new Date(messagePlan.startDate).toString() === 'Invalid Date') {
                        errorMessages.push('Term Start Date must be valid.. MM/DD/YYYY.');
                    }
                }

                if (!messagePlan.billingDay || isNaN(messagePlan.billingDay) || parseInt(messagePlan.billingDay) < 1 || parseInt(messagePlan.billingDay) > 31) {
                    errorMessages.push('Billing Day is required and must be between 1 - 31');
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                    document.getElementById("column-right").scrollTo({ top: 0, behavior: "smooth" });
                }

                return errorMessages.length === 0;
            },
            validateStartUserBilling(userPlan) {
                var errorMessages = [];

                if (!userPlan.billingTerm || userPlan.billingTerm < 1) {
                    errorMessages.push('Billing Term is required.');
                }

                if (!userPlan.planCount || isNaN(userPlan.planCount) || parseInt(userPlan.planCount) < 1) {
                    errorMessages.push('User Plan count is required.');
                }

                if (!userPlan.startDate || userPlan.startDate.trim() === '') {
                    errorMessages.push('Term Start Date is required');
                }
                else {
                    if (new Date(userPlan.startDate).toString() === 'Invalid Date') {
                        errorMessages.push('Term Start Date must be valid.. MM/DD/YYYY.');
                    }
                }

                if (!userPlan.billingDay || isNaN(userPlan.billingDay) || parseInt(userPlan.billingDay) < 1 || parseInt(userPlan.billingDay) > 31) {
                    errorMessages.push('Billing Day is required and must be between 1 - 31');
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                    document.getElementById("column-right").scrollTo({ top: 0, behavior: "smooth" });
                }

                return errorMessages.length === 0;
            },
            validateStopBilling(billingPlan) {
                var errorMessages = [];

                if (!billingPlan.endDate || billingPlan.endDate.trim() === '') {
                    errorMessages.push('Term End Date is required when cancelling.');
                }
                else {
                    if (new Date(billingPlan.endDate).toString() === 'Invalid Date') {
                        errorMessages.push('Term End Date must be valid.. MM/DD/YYYY.');
                    }
                    else if (new Date(billingPlan.endDate) < new Date(billingPlan.startDate)) {
                        errorMessages.push('Term End Date cannot be before Start Date');
                    }
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                    document.getElementById("column-right").scrollTo({ top: 0, behavior: "smooth" });
                }

                return errorMessages.length === 0;
            },
            delayCloseTermDropdown() {
                setTimeout(() => this.termDropdownActive = false, 500);
            },
        },
    }
</script>

<style scoped>
    .button-contain {
        display: flex;
        align-items: center;
        justify-items: center;
    }
</style>
<template>
  <div>
    <div v-show="!isConfirmationView">
      <div class="columns is-multiline">
        <div class="field column">
          <label class="switch-label">
            <input class="switch" type="checkbox" v-model="isEnabled" /> Enable
            Integration
          </label>
        </div>
      </div>
      <div class="columns is-multiline">
        <div v-if="isEnabled" class="column is-half">
          <div class="field">
            <label class="label">Tenant Id</label>
            <div class="control">
              <input class="input"
                     placeholder="Tenant Id..."
                     v-model="tenantId" />
            </div>
          </div>
          <div class="field">
            <label class="label">Cluster Id</label>
            <div class="control">
               <input class="input"
                      placeholder="Cluster Id..."
                      v-model="clusterId" />
            </div>
          </div>
          <Loading v-if="isLoading" />
          <div v-else-if="meevoLineIntegrations">
            <button class="button is-primary mt-3" @click="reset()">
              Reset Public Api Key
            </button>
            <div class="table-container mt-3">
              <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                  <tr>
                    <th>Phone Number</th>
                    <th>Location Id</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(meevoLineIntegration, index) in meevoLineIntegrations" 
                      :key="'meevo line integration' + index">
                    <td>{{ meevoLineIntegration.phoneNumber }}</td>
                    <td>{{ meevoLineIntegration.locationId }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isConfirmationView">
      <div class="modal-card pt-6">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Meevo Save Tenant Update</p>
          <button
            class="delete is-medium"
            @click="closeConfirmation()"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <p>
              Taking this action will generate a new public api key and call
              Meevo to update all lines on the account with a Meevo integration.
            </p>
            <p class="has-text-warning mt-2">Click "OK" to continue.</p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            :class="
              isLoading ? 'button is-primary is-loading' : 'button is-primary'
            "
            @click="confirm()"
          >
            OK
          </button>
          <button class="button is-primary" @click="closeConfirmation()">
            Cancel
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { secureGet, securePut } from "../secureFetch.js";

import Loading from "../components/Loading";

export default {
  name: "Meevo",
  components: {
    Loading,
  },
  props: {
    account: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hostUrl: function () {
      return this.$store.state.hostUrl;
    },
  },
  data() {
    return {
      isLoading: false,
      isEnabled: false,
      errorMessage: null,
      meevoLineIntegrations: null,
      tenantId: null,
      clusterId: null,
      defaultErrorMessage: "Something didn't work.",
      isConfirmationView: false,
      isConfirmReset: false,
      currentTenantId: null,
      currentClusterId: null,
    };
  },
  watch: {
    errorMessage(value) {
      this.$emit("error", value);
    },
  },
  mounted() {
    if (this.account && this.account.meevoTenantId) {
      this.tenantId = this.account.meevoTenantId;
      this.currentTenantId = this.account.meevoTenantId;
      this.clusterId = this.account.meevoClusterId;
      this.currentClusterId = this.account.meevoClusterId;
      this.getMeevoLineIntegrations();
      this.isEnabled = true;
    }
  },
  methods: {
    save() {
      this.errorMessage = null;
      if (this.tenantId != this.currentTenantId) {
        this.showConfirmation();
      }
      else if (this.clusterId != this.currentClusterId) {
          this.account.meevoClusterId = this.clusterId;
          this.updateAccount();
      }
      else {
        this.errorMessage = "No changes to save.";
      }
    },
    reset() {
      this.isConfirmReset = true;
      this.errorMessage = null;
      this.showConfirmation();
    },
    updateAccount() {
      if (this.validateAccount()) {
          this.updateAccount();
      }
    },
    confirm() {
      this.errorMessage = null;
      if (this.isConfirmReset === true) {
        this.resetPublicApiKey();
      } else {
          this.account.meevoTenantId = this.tenantId;
          this.account.meevoClusterId = this.clusterId;
          this.updateAccount();
      }

      this.closeConfirmation();
    },
    closeConfirmation() {
      this.isConfirmationView = false;
      this.isConfirmReset = false;
    },
    showConfirmation() {
      this.isConfirmationView = true;
    },
    getMeevoLineIntegrations() {
      this.isLoading = true;

      secureGet(
        this.hostUrl +
          "v1/meevo/line-integrations-with-phone-numbers?publicAccountId=" +
          this.account.publicId
      ).then((data) => {
        if (data && data.success) {
          this.meevoLineIntegrations = data.meevoLineIntegrations;
        }

        this.isLoading = false;
      });
    },
    updateAccount() {
      this.isLoading = true;

      securePut(
        this.hostUrl + "v1/account/" + this.account.publicId,
        this.account
      )
        .then((data) => {
          this.isLoading = false;

          if (!data.success) {
            this.errorMessage = data.message;

            if (!this.errorMessage) {
              this.errorMessage = this.defaultErrorMessage;
            }
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.errorMessage = this.defaultErrorMessage;
          console.error(e);
        });
    },
    validateAccount() {
      var errorMessages = [];

      if (!this.account.name || this.account.name.trim() === "") {
        errorMessages.push("Name is required");
      }

      if (!this.account.companyName || this.account.companyName.trim() === "") {
        errorMessages.push("Company Name is required");
      }

      if (!this.account.email || this.account.email.trim() === "") {
        errorMessages.push("Contact Email is required");
      }

      if (
        !this.account.productAccess ||
        this.account.productAccess.length < 1
      ) {
        errorMessages.push("At least one product permission is required");
      }
      if (
        this.tenantId &&
        (isNaN(this.tenantId) || parseInt(this.tenantId) < 1)
      ) {
        errorMessages.push("Invalid Meevo tenant id provided.");
      }

      if (errorMessages.length > 0) {
        this.errorMessage = errorMessages.join("; ");
      }

      return errorMessages.length === 0;
    },
    resetPublicApiKey() {
      this.isLoading = true;
      securePut(
        this.hostUrl +
          "v1/meevo/reset-public-api-key?publicAccountId=" +
          this.account.publicId
      )
        .then((data) => {
          this.isLoading = false;
          if (!data.success) {
            this.errorMessage = data.message;

            if (!this.errorMessage) {
              this.errorMessage = this.defaultErrorMessage;
            }
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.errorMessage = this.defaultErrorMessage;
          console.error(e);
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="field">
        <label class="switch-label">
            <input class="switch" type="checkbox" v-model="isEnabled" /> Enable Integration
        </label>
    </div>
    <div v-if="isEnabled" :class="integrationDropdownActive ? 'dropdown is-active' : 'dropdown'">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="integrationDropdownActive = !integrationDropdownActive" @blur="delayIntegrationDropdown">
            <span>{{ integrationTypeOptions.find(x => x.type == integrationType).label }}</span>
            <span class="icon is-small">
                <font-awesome-icon :icon="['fa', 'angle-down']" />
            </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
              <a role="button"
                 v-for="(option, index) in integrationTypeOptions"
                 :key="index"
                 :value="option.type"
                 :class="integrationType == option.type ? 'dropdown-item is-active' : 'dropdown-item'"
                 @click="setIntegrationType(option.type)">
                  {{option.label}}
              </a>
          </div>
      </div>
    </div>
    <div v-if="isEnabled && integrationType == 2" class="mt-4">
      <div v-if="hasRingCentralExternalAccount">
          <div class="columns is-multiline mb-0">
              <div class="field column is-half">
                  <label class="label">RingCentral Subscription Id</label>
                  <p class="has-text-grey-lighter mt-3"> {{ ringCentralExternalAccount.ringCentralSubscriptionId }} </p>
              </div>
              <div class="field column is-half">
                  <label class="label">RingCentral App Id</label>
                  <p class="has-text-grey-lighter mt-3"> {{ ringCentralExternalAccount.ringCentralAppId }} </p>
              </div>
          </div>
          <div class="columns is-multiline mb-0">
              <div class="field column is-half">
                  <label class="label">Bot User Id</label>
                  <p class="has-text-grey-lighter mt-3"> {{ ringCentralExternalAccount.botUserId }} </p>
              </div>
          </div>
          <h1 class="title">Troubleshooting</h1>
          <div class="columns is-multiline">
              <div class="column is-half">
                  <div class="card">
                      <header>
                          <p class="card-header-title">User Validation</p>
                      </header>
                      <div class="card-content pl-4 pt-0">
                          <button :class="isValidatingUsers ? 'button is-primary is-loading' : 'button is-primary'" @click="validateUsers">Validate Users</button>
                          <div v-if="validatedUsers" class="pt-4">
                              <div v-if="invalidUsersErrorFileUrl != null" class="has-accent-orange has-message-padding">
                                  <p>Results</p>
                                  <p>{{validateUsersMessage}}<a @click="downloadTroubleshootingFile(invalidUsersErrorFileUrl, 'InvalidUsers.csv')"> Download Results</a></p>
                                  <a ref="fileLink" download="InvalidUsers.csv" hidden></a>
                              </div>
                              <div v-else class="has-accent-green has-message-padding">
                                  <p>Results</p>
                                  <p>{{validateUsersMessage}}</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              
              <div class="column is-half">
                  <div class="card">
                      <header>
                          <p class="card-header-title">Team Validation</p>
                      </header>
                      <div class="columns is-multiline pl-4">
                          <div class="column is-narrow">
                              <p>Outbound Number</p>
                              <div class="control">
                                  <input class="input" type="text" v-model="outboundNumber" />
                              </div>
                          </div>
                          <div class="column is-narrow">
                              <p>Mobile Number</p>
                              <div class="control">
                                  <input class="input" type="text" v-model="mobileNumber" />
                              </div>
                          </div>
                      </div>
                      <div class="card-content pl-4 pt-0">
                          <button :class="isValidatingTeam ? 'button is-primary is-loading' : 'button is-primary'" @click="validateTeam">Validate Team</button>
                          <div v-if="validatedTeam" class="pt-4">
                              <div v-if="memberFileUrl != null" class="has-accent-green has-message-padding">
                                  <p>Results</p>
                                  <p>Team exists.<a @click="downloadTroubleshootingFile(memberFileUrl, 'TeamMembers.csv')"> Download team member list.</a></p>
                                  <a ref="fileLink" download="TeamMembers.csv" hidden></a>
                              </div>
                              <div v-else class="has-accent-orange has-message-padding">
                                  <p>Results</p>
                                  <p>Team does not exist. Check users and line permissions.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div v-else>
          <p> A Bot has not been installed from our platform. </p>
      </div>
    </div>
    <div v-else-if=" isEnabled && integrationType == 8" class="mt-4">
      <div class="columns is-multiline">
        <div class="field column mt-4 is-half">
          <label class="label">External Account Id</label>
          <div class="control">
              <input class="input" type="text" v-model="ringCxExternalAccount.externalAccountId">
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="field column is-half">
          <label class="label">External Master Account Id</label>
          <div class="control">
              <input class="input" type="text" v-model="ringCxExternalAccount.externalMasterAccountId">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import { secureGet, securePost, securePatch } from '../secureFetch.js';

    export default {
        name: 'RingCentralValidation',
        props: {
            publicAccountId: {
                type: String,
                default: null
            },
            initialIntegrationType: {
                type: Number,
                default: 0
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
            hasRingCentralExternalAccount: function () {
                return this.integrationType == 2 && this.ringCentralExternalAccount !== null;
            }
        },
        data() {
            return {
                isValidatingUsers: false,
                isValidatingTeam: false,
                ringCentralExternalAccount: null,
                ringCxExternalAccount: {},
                validatedUsers: false,
                validatedTeam: false,
                validateUsersMessage: "",
                invalidUsersErrorFileUrl: null,
                memberFileUrl: null,
                outboundNumber: "",
                mobileNumber: "",
                errorMessage: null,
                isEnabled: false,
                integrationDropdownActive: false,
                integrationType: 0,
                integrationTypeOptions: [
                  { label: "Select", type: 0 },
                  { label: "RingCentral MVP", type: 2 },
                  { label: "RingCX", type: 8},
                ],
                ringCxAccountExists: false,
            }
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
                console.log('error', value);
            }
        },
        mounted() {
            if (this.initialIntegrationType > 0) {
                this.isEnabled = true;
                this.integrationType = this.initialIntegrationType;
            }
            this.getRingCentralExternalAccount();
            this.getRingCxExternalAccount();
        },
        methods: {
            delayIntegrationDropdown() {
                setTimeout(() => this.integrationDropdownActive = false, 500);
            },
            setIntegrationType(type) {
                this.integrationType = type;
            },
            getRingCentralExternalAccount() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/ringcentral/" + this.publicAccountId)
                    .then(data => {
                        if (data && data.success) {
                            this.ringCentralExternalAccount = data.ringCentralExternalAccount;
                            // because Ring Central MVP integration type was not being set initially
                            if (this.initialIntegrationType < 1 && this.ringCentralExternalAccount != null) {
                                this.isEnabled = true;
                                this.integrationType = 2;
                            }
                        }

                        this.isLoading = false;
                    })
            },
            getRingCxExternalAccount() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/ringcx/account/" + this.publicAccountId)
                    .then(data => {
                        if (data && data.success && data.ringCxAccount != null) {
                            this.ringCxExternalAccount = data.ringCxAccount;
                            this.ringCxAccountExists = true;
                        }

                        this.isLoading = false;
                    })
            },
            save() {
                this.errorMessage = null;

                if (this.validateIntegration()) {
                  if (this.initialIntegrationType > 0 && !this.isEnabled) {
                      this.$emit("delete-account-integration", this.initialIntegrationType);
                      this.initialIntegrationType = 0;
                  }
                  else if (this.initialIntegrationType > 0 &&
                           this.initialIntegrationType != this.integrationType) {
                      this.$emit("delete-account-integration", this.initialIntegrationType);
                  }
                  if (this.isEnabled) {
                      if (this.initialIntegrationType != this.integrationType) {
                          this.$emit("create-account-integration", this.integrationType);
                      }

                      if (this.integrationType == 8) {
                        if (this.ringCxAccountExists) {                   
                            this.updateRingCxIntegration();
                        }
                        else {
                            this.createRingCxIntegration();
                        }
                      }
                  }
                }
            },
            validateUsers() {
                this.isValidatingUsers = true;

                secureGet(this.hostUrl + "v1/ringcentral/validate-users/" + this.publicAccountId)
                    .then(data => {
                        if (data) {
                            this.invalidUsersErrorFileUrl = data.errorFileUrl;
                            if (data.success) {
                                this.validatedUsers = true;
                                this.validateUsersMessage = "All Textel users match an active user in RingCentral.";
                            }
                            else if (data.errorFileUrl != null) {
                                this.validatedUsers = true;
                                this.validateUsersMessage = "Some of the Textel users do not match an active user in RingCentral.";
                            }
                            else {
                                this.errorMessage = data.message;
                            }
                        }

                        this.isValidatingUsers = false;
                    })
            },
            validateTeam() {
                this.isValidatingTeam = true;

                securePost(this.hostUrl + "v1/ringcentral/validate-team", { accountPublicId: this.publicAccountId, outboundNumber: this.outboundNumber, mobileNumber: this.mobileNumber })
                    .then(data => {
                        if (data && data.success) {
                            this.memberFileUrl = data.memberFileUrl;
                            this.validatedTeam = true;
                        }
                        else {
                            this.errorMessage = data.message;
                            this.memberFileUrl = null;
                            this.validatedTeam = true;
                        }

                        this.isValidatingTeam = false;
                    })
            },
            downloadTroubleshootingFile(url, fileName) {

                securePost(this.hostUrl + "v1/ringcentral/download-troubleshooting-file", { url: url, fileName: fileName })
                    .then(data => {
                        if (data.success) {
                            const element = this.$refs.fileLink;
                            const csv = data.file;
                            const fileStuff = `data:text/csv;base64,${csv.fileContents}`;
                            element.href = fileStuff;
                            element.click();
                        }
                    })
            },
            createRingCxIntegration() {
                this.isLoading = true;
                securePost(`${this.hostUrl}v1/ringcx/account/` + this.publicAccountId, this.ringCxExternalAccount)
                    .then((data) => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.ringCxAccountExists = true;
                            this.initialIntegrationType = this.integrationType;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            // update ringcx integration
            updateRingCxIntegration() {
                this.isLoading = true;
                securePatch(`${this.hostUrl}v1/ringcx/account/` + this.publicAccountId, this.ringCxExternalAccount)
                    .then((data) => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.initialIntegrationType = this.integrationType;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            validateIntegration() {
                var errorMessages = [];

                if (this.integrationType < 1) {
                  errorMessages.push('Must Select Integration Type');
                }

                if (this.integrationType == 8) {
                    if (!this.ringCxExternalAccount.externalAccountId || this.ringCxExternalAccount.externalAccountId.length < 1) {
                        errorMessages.push('External Account Id is required');
                    }
                    else if (isNaN(this.ringCxExternalAccount.externalAccountId)) {
                        errorMessages.push('External Account Id must be a number');
                    }

                    if (this.ringCxExternalAccount.externalMasterAccountId && this.ringCxExternalAccount.externalMasterAccountId.length > 1 &&
                        isNaN(this.ringCxExternalAccount.externalMasterAccountId)) {
                        errorMessages.push('External Master Account Id must be a number');
                    }
                }

                if (errorMessages.length > 0) {
                    this.errorMessage = errorMessages.join('; ');
                }

                return errorMessages.length === 0;
            }
        }
    };
</script>

<style scoped>
    .has-message-padding {
        padding: 0.1rem 0 0.1rem 0.5rem;
    }

    .has-accent-green {
        border-left: 4px solid #1F5D51;
    }

    .has-accent-orange {
        border-left: 4px solid #A1392A;
    }
</style>
export default {
    productIds: {
        Conversations: 1,
        Administration: 2,
        Blast: 3,
        Responders: 4,
        Reporting: 5,
        RingCentral: 7,
        PublicApi: 8,
        Nice: 9,
        InternalSystemsApi: 10,
        Genesys: 11,
        TestRunnerApi: 12,
        ChatBot: 13,
        LineIntelligence: 14,
        Translation: 15,
        Payments: 16,
        NiceDFO: 17
    }
}
<template>
    <div>
        <div class="columns is-multiline mb-0">
            <div class="field column is-narrow">
                <label class="label"> Message Tail Size </label>
                <div :class="messageTailDropdownActive ? 'dropdown is-active' : 'dropdown'">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="messageTailDropdownActive = !messageTailDropdownActive" @blur="delayCloseMessageTailDropdown">
                            <span>{{ messageTailSize }}</span>
                            <span class="icon is-small">
                                <font-awesome-icon :icon="['fa', 'angle-down']" />
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a role="button"
                               v-for="(size, index) in messageTailSizeOptions"
                               :key="index"
                               :value="size"
                               :class="messageTailSize == size ? 'dropdown-item is-active' : 'dropdown-item'"
                               @click="setMessageTailSize(size)">
                                {{size}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field column is-narrow">
                <label class="label"> Line </label>
                <b-dropdown v-model="messageTailSelectedLines"
                            multiple>
                    <template #trigger>
                        <b-button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span>{{messageTailSelectedLines[0] ? messageTailSelectedLines[0].lineName + (messageTailSelectedLines.length > 1 ? " + (" + (messageTailSelectedLines.length-1) + ")" : "") : ""}}</span>
                            <span class="icon is-small">
                                <font-awesome-icon :icon="['fa', 'angle-down']" />
                            </span>
                        </b-button>
                    </template>

                    <b-dropdown-item v-for="(accountLine, index) in accountLines"
                                     :key="index"
                                     :value="accountLine"
                                     aria-role="listitem">
                        {{accountLine.lineName}} - {{accountLine.phoneNumber}}
                    </b-dropdown-item>

                </b-dropdown>
            </div>
            <div class="field column is-narrow">
                <button class="button is-primary" @click="getMessageTail">
                    Submit
                </button>
            </div>
        </div>
        <div class="table-container">
            <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                    <tr>
                        <th>Date ({{accountTimezone}})</th>
                        <th>Source</th>
                        <th>Destinations</th>
                        <th>Is Outgoing</th>
                        <th>Has Body</th>
                        <th>Has Attachment</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <th>Date ({{accountTimezone}})</th>
                        <th>Source</th>
                        <th>Destinations</th>
                        <th>Is Outgoing</th>
                        <th>Has Body</th>
                        <th>Has Attachment</th>
                    </tr>
                </tfoot>
                <tbody>
                    <tr v-for="(value, index) in messageTail"
                        :key="index"
                        :value="value">
                        <td>{{displayMessageTime(value.created)}}</td>
                        <td>{{value.source}}</td>
                        <td>{{String(value.destinations)}}</td>
                        <td>{{value.isOutgoing}}</td>
                        <td>{{value.hasBody}}</td>
                        <td>{{value.hasAttachment}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import moment from 'moment-timezone';

    import { securePost } from '../secureFetch.js';

    export default {
        name: 'MessageTail',
        props: {
            accountLines: {
                type: [],
                default: null
            },
            accountTimezone: {
                type: String,
                default: null
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
            }
        },
        data() {
            return {
                messageTailDropdownActive: false,
                messageTailSize: 50,
                messageTailSizeOptions: [50, 100, 200, 250, 350, 400],
                messageTailSelectedLines: [],
                messageTail: [],
                errorMessage: null
            }
        },
        methods: {
            delayCloseMessageTailDropdown() {
                setTimeout(() => this.messageTailDropdownActive = false, 500);
            },
            setMessageTailSize(size) {
                this.messageTailSize = size;
            },
            displayMessageTime(time) {
                const momentUtc = moment.utc(time);
                return momentUtc.clone().tz(this.accountTimezone).format("DD-MM-YYYY h:mm:ss A");
            },
            getMessageTail() {
                this.isLoading = true;

                securePost(this.hostUrl + "v1/line/message-tail", { lineIds: this.messageTailSelectedLines.map(x => x.lineId), tailSize: this.messageTailSize })
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            this.messageTail = data.messageTail;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
        }
    };
</script>
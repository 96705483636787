<template>
    <div>
        <div v-show="!isConfirmationView">
            <div v-if="loadedMerchantId">
                <div v-if="allowMerchantIdUpdate">                    
                    <label class="label">AuthVia Merchant Id</label>
                    <div class="control mb-3">
                        <input class="input" placeholder="AuthVia Merchant Id..." v-model="account.authViaMerchantId">
                    </div>
                </div>             
                <div v-else class="columns">
                    <div class="field column is-narrow">
                        <label class="label">AuthVia Merchant Id</label>
                        <p class="has-text-grey-lighter mt-3"> {{ account.authViaMerchantId }}</p>
                    </div>                 
                    <button class="button is-primary update-button"
                            @click="allowMerchantIdUpdate = true">
                        Update Id
                    </button>
                </div>
                <div v-if="apiKey" class="columns">
                    <div class="field column is-narrow">
                        <label class="label">Api Key (SAVE THIS before leaving this page)</label>
                        <p class="has-text-grey-lighter mt-3"> {{ apiKey }}</p>
                    </div>
                </div>               
            </div>
            <div v-else>
                <div class="field column is-narrow">
                    <label class="switch-label">
                        <input class="switch" type="checkbox" v-model="isAxepay" /> Is Axepay Merchant
                    </label>
                </div>
                <div class="field">
                    <label class="label">Textel Host Override (for DEVELOPERS ONLY) (for local merchants)</label>
                    <div class="control">
                        <input class="input"
                               placeholder="developer.ngrok.io..."
                               v-model="textelHostOverride" />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="isConfirmationView">
            <div class="modal-card pt-6">
                <header class="modal-card-head">
                    <p class="modal-card-title">Confirm Merchant Creation</p>
                    <button class="delete is-medium"
                            @click="closeConfirmation()"
                            aria-label="close"></button>
                </header>
                <section class="modal-card-body">
                    <div class="content">
                        <p>Taking this action will generate a new public api key.</p>
                        <p class="has-text-warning mt-2">Click "OK" to continue.</p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'"
                            @click="confirm()">
                        OK
                    </button>
                    <button class="button is-primary" @click="closeConfirmation()">
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { securePost, securePut } from "../secureFetch.js";

import Loading from "../components/Loading";

export default {
  name: "AuthViaMerchantSettings",
  components: {
    Loading,
  },
  props: {
    account: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hostUrl: function () {
      return this.$store.state.hostUrl;
    },
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      defaultErrorMessage: "Something didn't work.",
      isConfirmationView: false,
      isAxepay: false,
      textelHostOverride: "",
      apiKey: "",
      loadedMerchantId: "",
      allowMerchantIdUpdate: false,
    };
  },
  watch: {
    errorMessage(value) {
      this.$emit("error", value);
    },
  },
  mounted() {
    this.loadedMerchantId = this.account.authViaMerchantId;
  },
  methods: {
    save() {
        // When we go to save, if we're creating a merchant, prompt the user for confirmation.
        if (!this.loadedMerchantId && !this.account.authViaMerchantId) {
            this.isConfirmationView = true;
        // Otherwise, when we go to save, we're updating the account's AuthVia merchant Id
        } else if (this.loadedMerchantId !== this.account.authViaMerchantId) {
            if (this.validateAccount()) {
                this.updateAccount();
            }
        }
    },
    confirm() {
      this.createMerchant();
      this.closeConfirmation();
    },
    closeConfirmation() {
      this.isConfirmationView = false;
    },
    createMerchant() {
      this.isLoading = true;

      let request = {
        publicAccountId: this.account.publicId,
        isAxepay: this.isAxepay,
        textelHostOverride: this.textelHostOverride,
      };

      securePost(this.hostUrl + "v1/authvia/merchant", request)
        .then(data => {
          this.isLoading = false;

          if (data && data.success) {
            this.account.authViaMerchantId = data.authViaMerchantId;
            this.loadedMerchantId = data.authViaMerchantId;
            this.apiKey = data.apiKey;
          }
          else {
            this.errorMessage = data.message;

            if (!this.errorMessage) {
              this.errorMessage = this.defaultErrorMessage;
            }
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.errorMessage = this.defaultErrorMessage;
          console.error(e);
        });
    },
    validateAccount() {
      var errorMessages = [];

      if (!this.account.name || this.account.name.trim() === "") {
        errorMessages.push("Name is required");
      }

      if (!this.account.companyName || this.account.companyName.trim() === "") {
        errorMessages.push("Company Name is required");
      }

      if (!this.account.email || this.account.email.trim() === "") {
        errorMessages.push("Contact Email is required");
      }

      if (!this.account.productAccess || this.account.productAccess.length < 1) {
        errorMessages.push("At least one product permission is required");
      }

      if (errorMessages.length > 0) {
        this.errorMessage = errorMessages.join("; ");
      }

      return errorMessages.length === 0;
    },
    updateAccount() {
      this.isLoading = true;

      securePut(
        this.hostUrl + "v1/account/" + this.account.publicId,
        this.account
      )
        .then((data) => {
          this.isLoading = false;

          if (!data.success) {
            this.errorMessage = data.message;

            if (!this.errorMessage) {
              this.errorMessage = this.defaultErrorMessage;
            }
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.errorMessage = this.defaultErrorMessage;
          console.error(e);
        });

      this.loadedMerchantId = this.account.authViaMerchantId;
      this.allowMerchantIdUpdate = false;
    },
  },
};
</script>
<style scoped>
    .update-button {
        margin-top: 2.3em;
    }
</style>